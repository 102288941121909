.braintree-form__label, .braintree-sheet__text {
    font-family: "AvenirBlack", sans-serif !important;
    font-weight: bold !important;
}

.braintree-form__notice-of-collection {
    display: none;
}

.braintree-form-cardholder-name {
    opacity: 0.5 !important;
}
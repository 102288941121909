@import "../../styles/variables";

.container {
	padding: 0 10px;
}

.block {
	width: 100%;
	height: 7px;
	background: $color-indigo;
	margin-bottom: 10px;
	border-radius: 3px;
	overflow: hidden;
}

.progress {
	width: 0;
	height: 100%;
	background: $color-lime;
}

.links {
	display: flex;
	justify-content: center;
	font-size: 14px;
}

.links > .link {
	width: 33.33%;
	text-align: center;
}

.links a {
	display: block;
	color: $color-indigo;
	text-decoration: none;
}

.links p {
	margin: 0;
	display: block;
	color: $color-indigo;
	opacity: 0.4;
}

.active {
	font-family: $font-heading;
	opacity: 1 !important;
}

.separator {
	opacity: 0.4;
	width: 12px;
}

@include large() {
	.links {
		font-size: 18px;
	}

	.links > .link {
		width: 150px;
		border: none;
	}

	.links > div:last-of-type {
		border: none;
	}

	.activeBlock {
		position: absolute;
		bottom: -12px;
		left: 25px;
		width: 100px;
		height: 6px;
		background: $color-lime;
		border-radius: 3px;
	}

	.activeStep2 {
		left: calc(150px + 25px + 12px);
	}

	.activeStep3 {
		left: calc(150px + 150px + 25px + 24px);
	}
}
@import "../../styles/variables";

.backBtn {
	display: none;
}

.nextBtn {
	width: 100%;
}

@include large() {
	.backBtn {
		display: block;
		margin-right: 20px;
		width: calc(50% - 10px);
	}

	.nextBtn {
		width: calc(50% - 10px);
	}
}


@import "../../styles/variables";

.button {
	background: $color-spearmint;
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	font-size: 16px;
	line-height: 19px;
	font-family: $font-heading;
	cursor: pointer;
}

.none {
	display: flex;
	cursor: pointer;
	background: none !important;
}
@import "../../styles/variables";

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 20;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
}

.overlay img {
	width: 120px;
}

.fixed {
	position: fixed;
}
@import "../../styles/variables";

.container {

}

.content {
	background: $color-white;
	padding: 40px 20px;
	border-radius: 15px;
}

.separator {
	height: 3px;
	background: $color-off-green;
	margin: 30px 0 50px;
}
@import "../../styles/variables";

.inner {
	display: inline-flex;
	flex-direction: column;
	width: 100%;
}

.inputContainer {
	display: inline-block;
	position: relative;
}

.input {
	height: 50px;
	width: 100%;
	border-radius: 4px;
	border: 1px solid $color-grey;
	padding: 10px 15px;
	outline: none;
	background: $color-grey;
	color: $color-indigo;
	font-family: $font-heading;
}

@include large() {
	.input {
		border: 1px solid rgba(0, 67, 70, 0.5);
	}
}
.error {
	display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    color: #a94442;
    background: #f2dede;
    border: 1px solid #ebccd1;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
}
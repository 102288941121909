@import "../../styles/variables";

.container {
	position: relative;
}

.content {
	background: $color-white;
	padding: 40px 20px;
	border-radius: 15px;
}

.separator {
	height: 3px;
	background: $color-off-green;
	margin: 30px 0 50px;
}

.method {
	width: 100%;
	height: 55px;
	border-radius: 5px;
	border: 1px solid $color-indigo;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	padding: 0 15px;
	cursor: pointer;
}

.method:hover {
	border-color: $color-indigo;
}

.method.active {
	background: $color-spearmint;
}

.methodWithNonce.active {
	background: $color-spearmint;
}

.methodWithNonce:hover {
	border-color: $color-indigo;
}

.methodError .method {
	border-color: $color-red;
}

.methods > div:first-of-type {
	margin: 0 0 10px 0;
}

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $color-white;
	opacity: 0.8;
	z-index: 2;
}

.methodWithNonce {
	width: 100%;
	height: 55px;
	border-radius: 5px;
	border: 1px solid $color-indigo;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	padding: 0 15px;
	cursor: pointer;
}

.input {
	height: 50px;
	width: 100%;
	border-radius: 4px;
	border: 1px solid $color-grey;
	color: $color-indigo;
	padding: 10px 15px;
	outline: none;
}

@include large() {
	.methods > div:first-of-type {
		margin: 0 10px 0 0;
	}

	.method {
		display: inline-flex;
		width: calc(50% - 5px);
		border: 1px solid rgba(0, 67, 70, 0.5);
	}

	.methodWithNonce {
		display: inline-flex;
		width: calc(31% - 5px);
		border: 1px solid rgba(0, 67, 70, 0.5);
	}
}
@font-face {
    font-family: "engrezbold";
    src: url("../fonts/engrez-bold-webfont.woff2") format("woff2"), 
    url("../fonts/engrez-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "engrezmedium";
    src: url("../fonts/engrez-medium-webfont.woff2") format("woff2"), 
    url("../fonts/engrez-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "engrezregular";
    src: url("../fonts/engrez-webfont.woff2") format("woff2"), 
    url("../fonts/engrez-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "engrezlight";
    src: url("../fonts/engrez-light-webfont.otf") format("opentype"), 
    url("../fonts/engrez-light-webfont.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: $font-regular;
    color: $color-indigo;
    font-size: 16px;
    line-height: 20px;
    font-synthesis: none;
}

.bold {
    font-family: $font-heading;
}

.regular {
    font-family: $font-regular;
}

.light {
    font-family: $font-light;
}

.underline {
    text-decoration: underline;
}

.font-tiny {
    font-size: 12px;
    line-height: 16px;
}

.font-smaller {
    font-size: 14px;
    line-height: 18px;
}

.font-small {
    font-size: 16px;
    line-height: 20px;
}

.font-medium {
    font-size: 18px;
    line-height: 22px;
}

.font-large {
    font-size: 20px;
    line-height: 24px;
}

.font-larger {
    font-size: 22px;
    line-height: 26px;
}

.font-xlarge {
    font-size: 24px;
    line-height: 28px;
}

a:link,
a:visited {
    color: $color-lime;
    text-decoration: none;
}

a:hover,
a:active {
    color: $color-lime;
    text-decoration: underline;
}

h1 {
    font-size: 18px;
    line-height: 24px;
    font-family: $font-heading;
}

h2 {
    font-size: 18px;
    line-height: 22px;
    font-family: $font-regular;
}

@include large() {
    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 20px;
        line-height: 24px;
    }
}

.color-indigo {
    color: $color-indigo;
}

.color-lime {
    color: $color-lime;
}

.color-malachite {
    color: $color-malachite;
}

.color-red {
    color: $color-red;
}

// carousel active dot color for UpsellPlanCard

.rec .rec-dot_active {
    background-color: $color-lime !important;
    -webkit-box-shadow: 0 0 1px 3px $color-spearmint !important;
    box-shadow: 0 0 1px 3px $color-spearmint !important;
}

// carousel extra space for UpsellPlanCard shadown

.rec-item-wrapper {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

@import "../../styles/variables";

.summary {
	border-radius: 10px;
	background: $color-white;
	padding: 18px 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.summaryTitle {
	font-family: $font-heading;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.expandable {
	padding: 0 10px;
	font-size: 14px;
	overflow: hidden;
}

.separator {
	width:100%;
	height: 2px;
	background: $color-spearmint;
	margin: 15px 0;
}

.total {
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 15px 10px;
	margin-top: 20px;
}

.price {
	font-family: $font-heading;
	font-size: 18px;
	line-height: 24px;
	margin: 0;
}

@include large() {
	.summary {
		margin: 0;
		border-radius: 15px;
		padding: 40px 50px;
		line-height: 28px;
	}

	.separator {
		height: 3px;
		background: $color-off-green;
		margin: 40px 0;
	}

	.price {
		font-size: 20px;
	}

	.total {
		border-radius: 10px;
		background: none;
		font-size: 20px;
	}
}

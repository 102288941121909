@import "../../styles/variables";

.header {
	margin-bottom: 20px;
}

.callUs {
	display: none;
}

.confirmed {
	display: none;
	text-align: center;
	width: 50%;
}

.confirmed > div {
	position: relative;
	display: inline-block;
	font-size: 20px;
	font-family: $font-heading;
}

.underline {
	position: absolute;
	bottom: -12px;
	left: 0;
	width: 100%;
	height: 6px;
	background: $color-lime;
	border-radius: 3px;
}

.logoutButton {
	font-family: $font-heading;
	margin: 0;
	margin-bottom: 2;
	text-decoration-line: underline;
	cursor: pointer;
}

@include large() {
	.confirmed {
		display: block;
	}

	.header {
		display: flex;
		position: relative;
		align-items: center;
		margin-top: 20px;
		background: $color-white;
		border-radius: 15px;
		padding: 20px 10px 20px 40px;
	}

	.callUs {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 10px;
		top: 10px;
		height: calc(100% - 20px);
		padding: 0 35px;
		font-size: 18px;
		width: calc(41.66666667% - 20px);
	}

	.callUs a {
		color: $color-indigo;
		text-decoration: none;
	}
}
@import "../../styles/variables";

.error {
	color: $color-error;
}
section {
	display: flex;
}

.formCheckInput {
	width: 1.25rem;
    height: 1.25rem;
}
@import "../../styles/variables";

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: calc(50% - 6px);
	border-radius: 5px;
	border: 0.5px solid rgba(0, 67, 70, 0.5);
	font-size: 15px;
	font-family: $font-heading;
	background-color: $color-white;
	cursor: pointer;
}
@media (max-width: 500px) {
	.button {
		font-size: 12px;
	}
}
.active {
	background-color: $color-spearmint;
}

.error .button {
	border-color: $color-red;
}

@include large() {
	.button {
		max-width: 235px;
	}
}
@import "../../styles/variables";

.container {
	display: flex;
	align-items: flex-start;
}

.label input {
	display: none;
}

.label {
	line-height: 22px;
}

.checkboxContainer {
	margin-top: 3px;
}

.checkbox {
	height: 25px;
	width: 25px;
	border-radius: 4px;
	border: 1px solid $color-indigo;
	margin-right: 20px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;
}

.checkbox.active {
	background: $color-spearmint;
}

.checkbox img {
	max-width: 100%;
}

.large {
	height: 40px;
	width: 40px;
	margin-right: 0;
}

.text {
	font-size: 14px;
	line-height: 18px;
}

.error input {
	//outline: 1px solid $color-red !important;
}

.error .label {
	color: $color-red;
}

.error .label a {
	color: $color-red;
	text-decoration: underline;
}

@include large() {
	.container {
		align-items: center;
	}
}

@import "../../styles/variables";

.container {
    display: block;
    background: $color-white;
    border-radius: 10px;
    padding-top: 35px;
    padding-bottom: 35px;
    position: relative;
}

.contentCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.logo {
    width: 14.125rem;
    margin-bottom: 40px;
}

.label {
    font-size: 0.75rem;
    margin-top: -0.625rem;
}

@include large() {
    .container {
        display: block;
    }
}

.plan {
    transition: all 0.2s;
    position: relative;

    @media screen and (min-width: 300px) and (max-width: 400px) {
        width: calc(93%);
        transition: all 0.2s;
        position: relative;
        margin: 20px 0px 20px 0px;
    }
}

@import "variables";

.not-allowed {cursor: not-allowed !important;}

.bg-grey-50 {
    background: $color-grey-50;
}

.bg-red-50 {
    background: $color-red-50;
}

.bg-green-50 {
    background: $color-green-50;
}
@import "../../styles/variables";

.addButton {
    background: $color-indigo;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;
    font-family: $font-heading;
    cursor: pointer;
    color: $color-white;
}

.removeButton {
    font-size: 16px;
    color: $color-black;
    background-color: transparent;
    border-radius: 16px;
}

.benefits {
    display: flex;
    display: flex;
    justify-content: start;
    align-items: baseline;
    margin-bottom: 12px;

    p {
        margin-left: 12px;
        text-align: left;
        margin-bottom: 0;
    }
}

.activeBorder {
    border: 6px solid $color-malachite;
    box-sizing: border-box;
    border-radius: 16px;
}

.planTitle {
    display: block;
    text-align: left;
    max-width: 10rem;
}

.labelSelected {
    background-color: $color-tea-green;
    padding: 4px 8px;
    border-radius: 16px;
}

.cardShadow {
    box-shadow: 0 0 10px 0 rgba(159, 14, 14, 0.08);
    border-radius: 10px;
}

.icon_tick {
    width: 12px;
}

.addBtn {
    font-size: 20px;
    color: $color-white;
    background-color: $color-indigo;
    border-radius: 16px;
}

.removeBtn {
    font-size: 16px;
    color: $color-black;
    background-color: transparent;
    border-radius: 16px;
    max-width: 61px;
    justify-content: flex-end;
}

.inner {
    background: $color-white;
    padding-left: 22px;
    padding-top: 17px;
    padding-bottom: 10px;
    padding-right: 16px;
    display: block;
    text-align: left;
    border-radius: 10px;
}

.innerGrey {
    background: $color-lightgrey;
    padding: 20px;
    display: block;
    text-align: center;
}

.card {
    max-width: 320px;
}

.innerIndigo {
    background-color: $color-indigo;
    color: $color-white;
    padding-top: 18px;
    padding-bottom: 20px;
    padding-right: 22px;
    padding-left: 25px;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
}

.addBtn {
    display: inline-flex;
    align-items: center;
    border-radius: 16px !important;
    padding: 15px 45px;
    background: $color-indigo;
    color: $color-white;
    transition: all 0.2s;
    font-family: $font-heading;
    text-align: center;
    width: 240px;
    justify-content: center;
}

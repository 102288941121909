@import "../../styles/variables";

.container {
    padding: 15px 30px;

    a {
        color: $color-indigo;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        img {
            max-width: 20px;
        }
    }

    .form {
        .error {
            font-size: 14px;
            color: #a94442;
            background: #f2dede;
            border: 1px solid #ebccd1;
            border-radius: 5px;
            padding: 15px;
            margin-top: 10px;
        }

        .button {
            background: $color-spearmint;            
            margin-left: auto;            
            border: none;
            padding: 15px 50px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-size: 16px;
            line-height: 19px;
            font-family: $font-heading;
            cursor: pointer;

            &:hover {
                transform: scale(1.05);
            }
        }
    }
}
@import "../../styles/variables";

.inner {
	display: inline-flex;
	flex-direction: column;
	width: 100%;
}

.inputContainer {
	display: inline-block;
	position: relative;
}

.input {
	height: 50px;
	width: 100%;
	border-radius: 4px;
	border: 1px solid $color-indigo;
	color: $color-indigo;
	padding: 10px 15px;
	outline: none;
}

.input:focus {
	background: $color-lightgrey;
}

.error .input {
	border-color: $color-red !important;
}

.error .input:focus {
	background: $color-white;
}

.errorIcon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: -30px;
	display: flex;
}

.errorIcon img {
	width: 20px;
}

@include large() {
	.input {
		border: 1px solid rgba(0, 67, 70, 0.5);
	}

	.input:hover {
		border-color: $color-indigo;
	}
}
@import "../../styles/variables";

.container {
	position: relative;
}

.content {
	background: $color-white;
	padding: 25px 25px 20px 25px;
	border-radius: 15px;
}

.separator {
	height: 3px;
	background: $color-off-green;
	margin: 30px 0 50px;
}
@import "../../styles/variables";

.container {

}

.inner {
	position: relative;
}

.input {
	height: 50px;
	width: 100%;
	border-radius: 4px;
	border: 1px solid rgba(0, 67, 70, 0.5);
	color: $color-indigo;
	padding: 10px 15px;
	outline: none;
    text-transform: uppercase;
}

.input:focus {
	background: $color-lightgrey;
}

.error .input {
	border-color: $color-red !important;
}

.error .input:focus {
	background: $color-white;
}

.results {
	position: absolute;
	top: 47px;
	border-radius: 0 0 15px 15px;
	z-index: 2;
	width: 100%;
	border: 1px solid rgba(0, 67, 70, 0.5);
	border-top: none;
	overflow: hidden;
	background: $color-white;
}

.link {
	display: block;
	padding: 15px;
	font-size: 14px;
	color: $color-indigo;
	border: none;
	width: 100%;
	text-align: left;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.link:nth-child(even) {
	background: $color-lightgrey;
}

.link:nth-child(odd) {
	background: $color-white;
}

.link:hover {
	color: $color-lime;
}

.noLink {
	display: block;
	padding: 15px 30px;
	font-size: 14px;
	color: $color-indigo;
	border: none;
	width: 100%;
	text-align: left;
}

.close {
	position: absolute;
	right: 20px;
	top: 15px;
	cursor: pointer;
}

.close img {
	width: 10px;
}

.errorIcon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	display: flex;
}

.errorIcon img {
	width: 20px;
}

.noFound {
    padding: 1.5rem 3rem 1.5rem 1.5rem;
}

.manual {
    padding: 15px 30px;
    font-size: 14px;
	color: $color-indigo !important;
	width: 100%;
	text-align: left;
    text-decoration: none !important;
    display: block;

    &:hover {
        color: $color-lime !important;
    }
}

@include large() {
	.link {
		padding: 15px 30px;
	}
}
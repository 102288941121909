@import "../../styles/variables";

.container {
	position: relative;
}

.tabs > div {
	width: 50%;
	border-radius: 15px 15px 0 0;
	padding: 15px;
	background: $color-off-blue;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 16px;
	cursor: pointer;
}

.tabs > div.active {
	background: $color-white;
}

.content {
	background: $color-white;
	padding: 40px 20px;
	border-radius: 0 0 15px 15px;
}
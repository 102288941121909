@import "../../styles/variables";

@include large() {
	.container {
		overflow: visible;
		border-radius: 15px;
	}

	.leftContainer {
		padding-right: 20px;
	}
}

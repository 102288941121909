@import "../../styles/variables";

.container {
	background: $color-white;
	border-radius: 10px;
	padding: 40px 20px;
	text-align: center;
}

.header {
	color: $color-lime;
	font-size: 26px;
	line-height: 34px;
	margin-bottom: 30px;
}

.image {
	max-width: 100%;
}

.test:link, .test:visited {
	text-decoration: underline;
	color: $color-indigo;
}
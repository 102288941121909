@import "../../styles/variables";

.content {
	background: $color-white;
	padding: 40px 50px;
	border-radius: 15px;
}

.separator {
	height: 3px;
	background: $color-off-green;
	margin: 20px 0;
}
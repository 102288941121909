@import "../../styles/variables";

.inner {
	display: inline-flex;
	flex-direction: column;
	width: 100%;
}

.inputContainer {
	display: inline-block;
	position: relative;
}

.input {
	height: 50px;
	width: 100%;
	border-radius: 4px;
	border: 1px solid $color-indigo;
	color: $color-indigo;
	padding: 10px 15px;
	outline: none;

	background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
	background-position: calc(100% - 0.75rem) center !important;
	-moz-appearance:none !important;
	-webkit-appearance: none !important;
	appearance: none !important;
}

.input:focus {
	background-color: $color-lightgrey;
}

.error .input {
	border-color: $color-red !important;
}

.error .input:focus {
	background: $color-white;
}

.errorIcon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: -30px;
	display: flex;
}

.errorIcon img {
	width: 20px;
}

@include large() {
	.input {
		border: 1px solid rgba(0, 67, 70, 0.5);
	}

	.input:hover {
		border-color: $color-indigo;
	}
}
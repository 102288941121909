@import "../../styles/variables";

.content {
	background: $color-white;
	padding: 40px 10px;
	border-radius: 15px;
}

.separator {
	height: 3px;
	background: $color-off-green;
	margin: 15px 0;
}

.table tr td {
	vertical-align: top;
	line-height: 28px;
}

@include large() {
	.content {
		padding: 40px 50px;
	}

	.separator {
		margin: 20px 0;
	}
}
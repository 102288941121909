.modal-header {
    display: block;
}

.btn-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.2rem;
    opacity: 0.8;    
}

.modal-content {
    padding: 20px 40px;
    box-shadow: 0px 40px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: none;
}

@media (max-width: 768px) {
    .modal-content {
        padding: 20px 10px;
    }

    .esim-body {
        padding: 0px;

        &>div {
            &:first-of-type {
                padding-top: 0px;
            }

            &:last-of-type {
                border-bottom: none;
            }

            padding: 20px 0;
            border-bottom: 1px solid #e0e0e0;
        }
    }
}